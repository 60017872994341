.success-alert .MuiAlert-icon {
  font-size: 40px;
  margin-top: 10px;
}

.alert-container {
  width: 610px;
}

@media only screen and (max-width: 610px) {
  .alert-container {
    width: 100%;
  }
}
