.center-everything {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

html,
body,
#root,
.full-width-height {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

.full-width {
  width: 100%;
}
